<template>
  <div class="widgetContainer widgetContainer--center widgetContainer--scrollable bg-white login">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('ssn_NavTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form
        @keyup.enter.native="submitForm()"
        :model="user"
        :rules="rules"
        ref="user"
        @submit.prevent.native
        class="wise-form">
        <el-form-item prop="otp" :label="$t('ssn_Title')">
          <SSNInput @input="ssnChange" :is-last4="true" :autofocus="true" :country="countryCode" />
        </el-form-item>
        <p class="wise-form__info">
          {{ $t('ssn_Desc') }}
        </p>
      </el-form>
      <div class="widgetContainer__footer--fixed">
        <el-button
          type="primary"
          @click="submitForm()"
          :disabled="isDisabled"
          class="el-button__brand brand">
          {{ $t('ssn_NavRightButton_Title') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import SSNInput from '@/components/SSNInput';
import { mapActions, mapGetters } from 'vuex';
import loginMixins from '../mixins/login';
import { getCountryDetailsFromPhoneNumber, getSupportedCountries } from '@/utils/env';

export default {
  name: 'Login',
  mixins: [loginMixins],
  components: {
    SSNInput
  },
  computed: {
    ...mapGetters('user', ['getUserDetails', 'getUserAuthToken']),
    isDisabled() {
      return !this.user.ssn || this.user.ssn.length != 4;
    },
    selectedCountry() {
      const supportedCountries = getSupportedCountries();
      return supportedCountries.find(c => c.code === this.countryCode);
    },
  },
  data() {
    return {
      user: {
        ssn: ''
      },
      rules: {
        ssn: 'required'
      },
      countryCode: 'US'
    };
  },
  created() {
    /** @type {import('@/modules/user/store/state').userState['userDetails']} */
    const user = this.getUserDetails;
    if (!user || !user.phone) {
      this.goToWelcomeScreen();
    }  else {
      const details = getCountryDetailsFromPhoneNumber(user.phone);
      if(details && details.code)
        this.countryCode = details.code;
    }
  },
  methods: {
    ...mapActions('user', ['verifyOtp', 'getPerson']),
    submitForm() {
      if (this.isDisabled) {
        return;
      }
      /** @type {import('@/modules/user/store/state').userState['userDetails']} */
      const user = this.getUserDetails;
      if (user && user.phone) {
        const loader = this.showLoader();
        const {accessToken} = this.getUserAuthToken;

        this.verify(accessToken, this.user.ssn)
          .catch((e) => {
            if(e.code === 'EC_NEW_DEVICE_LOGIN_FAILED') {
              this.apiErrorMessage(e.message);
            } else {
              this.$router.push('/welcome');
            }

          })
          .finally(() => {
            loader.close();
          });
      }
    },
    ssnChange(v) {
      this.user.ssn = v.value;
    }
  }
};
</script>
<style lang="scss">
.widgetContainer {
  &.login {
    .navigationHeader {
      min-height: 68px;
    }
    .widgetContainer__body {
      position: relative;
    }

  }
}
</style>
